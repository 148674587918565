<template>
  <!-- <div
    class="fluid d-flex justify-center align-center"
    style="background:rgba(255,255,255,0.9);height:88vh;"
  > -->
  <!-- <div>
    <template> -->
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          large
          tile
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          style="width:200px;"
        >
          Regenerate Report
        </v-btn>
      </template>
      <v-card
        class="d-flex justify-center align-center"
        style="position:relative;"
      >
        <div>
          <v-card-title>
            <span class="text-h5">Regenerate Attendance Report</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    class="attendance-start-date ml-1 mr-2"
                    dense
                    label="Start Date"
                    type="date"
                    name="startdate"
                    style="height:30px;"
                    step="1"
                    v-model="start_date"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    class="attendance-end-date ml-1 mr-2"
                    label="End Date"
                    type="date"
                    name="enddate"
                    step="1"
                    required
                    v-model="end_date"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    :value="2"
                    :items="dropDownCompany"
                    item-text="name"
                    item-value="id"
                    label="Company"
                    return-id
                    dense
                    v-model="company"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="save">
              Generate
            </v-btn>
          </v-card-actions>
        </div>
        <div
          v-if="loading"
          style="position:absolute; width:100%; height:100%; background:hsla(5,80%,90%,0.3);"
          class="d-flex justify-center align-center"
        >
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
  <!-- </template>
  </div> -->
</template>
<script>
import axios from 'axios'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  data: () => ({
    attendance: buildType.apiURL('attendance'),
    eSanqua: buildType.apiURL('esanqua'),
    dialog: false,
    company: 0,
    start_date: '',
    end_date: '',
    dropDownCompany: [],
    loading: false
  }),
  components: {},
  created() {
    const d = new Date()
    d.setDate(d.getDate() - 1)
    const n = d
      .toLocaleDateString('id')
      .split('/')
      .reverse()
      .join('-')
    this.start_date = n
    this.end_date = n
    this.company = parseInt(this.getUserProfile.employee.company.id)
    this.dropdownCompany()
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    dropdownCompany() {
      const application = this.getUserProfile.level
      // const result = application.find(({ application }) => application.id === 1)
      const result = application.find(({ id }) => id === '1' || id === '6')
      if (result !== undefined) {
        axios
          .get(`${this.eSanqua}master/universal/plant/dropdown`)
          .then(res => {
            console.log(res)
            this.dropDownCompany = res.data.data
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        this.dropDownCompany = [
          {
            id: this.getUserProfile.sanqua_company_id,
            name: this.getUserProfile.sanqua_company_name
          }
        ]
      }
    },
    save() {
      const form = {
        start_date: this.start_date,
        end_date: this.end_date,
        company_id: this.company
      }
      const x = new Date(form.start_date.replace('-', '/'))
      const z = new Date(form.end_date.replace('-', '/'))
      const diffInTime = z.getTime() - x.getTime()
      const q = diffInTime / (1000 * 3600 * 24)
      if (q < 30) {
        // this.showMsgDialog('success', 'saved', false)
        this.showMsgDialog('question', 'Generate Now', true).then(res => {
          if (res.isConfirmed) {
            this.loading = true
            console.log('regenerate')

            // axios
            //   .post(
            //     `${this.attendance}report/attendance/regenerate_report_attendance`,
            //     form,
            //     { timeout: 1000 }
            //   )
            axios({
              method: 'post',
              url: `${this.attendance}report/attendance/regenerate_report_attendance`,
              timeout: 300000,
              data: form
            })
              .then(res => {
                console.log(res)
                this.loading = false
                if (res.data.status_code !== '-99') {
                  this.showMsgDialog('success', res.data.status_msg, false)
                } else {
                  this.showMsgDialog('warning', res.data.status_msg, false)
                }
                this.dialog = false
              })
              .catch(err => {
                this.loading = false
                console.log(err)
                this.showMsgDialog('error', 'Please contant an admin', false)
                this.dialog = false
              })
          }
        })
      } else {
        this.showMsgDialog('warning', 'min 30 days', false)
      }
    }
  }
}
</script>

<style lang="scss"></style>
